@import 'core/settings/settings';
@import 'core/tools/tools';

.markdown {
  /**
   * Text variants
   */
  // Used on home page
  .display1 {
    font-size: calc-size(32px);
    line-height: calc-size(40px);
    @include set-breakpoint('small') {
      font-size: calc-size(64px);
      line-height: calc-size(70px);
    }
    @include set-breakpoint('x-large') {
      font-size: calc-size(72px);
      line-height: calc-size(72px);
    }
  }

  // Used on terms and conditions, confirmation info, income step
  .display2 {
    font-size: calc-size(32px);
    line-height: calc-size(40px);
    @include set-breakpoint('xx-small') {
      font-size: calc-size(36px);
      line-height: calc-size(45px);
    }
    @include set-breakpoint('small') {
      font-size: calc-size(42px);
      line-height: calc-size(60px);
    }
  }

  // Used on static pages
  .display3 {
    font-size: calc-size(get-font-size('xxx-small', 'display3'));
    line-height: calc-size(45px);
    @include set-breakpoint('small') {
      font-size: calc-size(get-font-size('small', 'display3'));
      line-height: calc-size(45px);
    }
  }

  // Used on terms and conditions, confirmation info, income step
  .display4 {
    font-size: calc-size(24px);
    line-height: calc-size(30px);
    @include set-breakpoint('small') {
      font-size: calc-size(30px);
      line-height: calc-size(38px);
    }
  }

  // Used on confirmation info, income step
  .heading2 {
    font-size: calc-size(22px);
    line-height: calc-size(30px);
    @include set-breakpoint('small') {
      font-size: calc-size(24px);
      line-height: calc-size(34px);
    }
  }

  .text {
    font-size: calc-size(18px);
    line-height: calc-size(25px);
  }

  .text-variant {
    font-size: calc-size(15px);
    line-height: calc-size(21px);
    @include set-breakpoint('small') {
      font-size: calc-size(20px);
      line-height: calc-size(28px);
    }
  }

  .text-variant-2 {
    font-size: calc-size(20px);
    line-height: calc-size(28px);
    @include set-breakpoint('small') {
      font-size: calc-size(22px);
      line-height: calc-size(30px);
    }
  }

  .list-variant {
    font-size: calc-size(16px);
    line-height: calc-size(20px);
    @include set-breakpoint('small') {
      font-size: calc-size(20px);
      line-height: calc-size(28px);
    }
  }

  /**
   * font-family
   */
  .font-primary {
    font-family: get-font('primary');
  }

  .font-secondary {
    font-family: get-font('secondary');
  }

  /**
   * font-weight
   */
  
  .font-light {
    font-weight: 300;
  }
  
  .font-normal {
    font-weight: normal;
  }

  .font-medium {
    font-weight: 500;
  }

  .font-semibold {
    font-weight: 600;
  }

  /**
   * text color
   */

  .text-black {
    color: $black;
  }

  .text-dark-blue {
    color: $dark-blue;
  }

  .text-gray {
    color: $gray;
  }

  /**
   * font-size
   */
  .text-m {
    font-size: 30px;
    line-height: 42px;

    @include set-breakpoint('small') {
      font-size: 36px;
      line-height: 50px;
    }
  }

  .text-b {
    font-size: 42px;
    line-height: 42px;

    @include set-breakpoint('xx-small') {
      font-size: 42px;
      line-height: 50px;
    }

    @include set-breakpoint('xxx-small') {
      font-size: 36px;
      line-height: 50px;
    }
  }

  /**
   * text-align
   */
  .text-center {
    text-align: center;
  }

  .text-left {
    text-align: left;
  }

  /**
   * margin
   */
  .m-0 {
    margin: 0;
  }

  .mt-0 {
    margin-top: 0;
  }

  .mt-100 {
    margin-top: calc-size(map-get($spacing, '100'));
  }

  .mt-400 {
    margin-top: calc-size(map-get($spacing, '400'));
  }

  .mt-500 {
    margin-top: calc-size(map-get($spacing, '500'));
  }

  .my-0 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .my-300 {
    margin-block: calc-size(map-get($spacing, '300'));
  }

  .mx-auto {
    margin-left: auto;
    margin-right: auto;
  }

  .mb-0 {
    margin-bottom: 0;
  }

  .mb-85 {
    margin-bottom: calc-size(map-get($spacing, '85'));
  }

  .mb-100 {
    margin-bottom: calc-size(map-get($spacing, '100'));
  }

  .mb-150 {
    margin-bottom: calc-size(map-get($spacing, '150'));
  }

  .mb-200 {
    margin-bottom: calc-size(map-get($spacing, '200'));
  }

  .mb-300 {
    margin-bottom: calc-size(map-get($spacing, '300'));
  }

  .mb-450 {
    margin-bottom: calc-size(map-get($spacing, '450'));
  }

  .mb-500 {
    margin-bottom: calc-size(map-get($spacing, '500'));
  }

  .mb-600 {
    margin-bottom: calc-size(map-get($spacing, '600'));
  }

  /**
   * Opacity
   */
  .opacity-50 {
    opacity: 0.5;
  }

  /**
   * List style type
   */
  .list-none {
    list-style-type: none;
  }

  .list-lower-alpha, .list-lower-alpha > ul, .list-lower-alpha > ol {
    list-style-type: lower-alpha;
  }

  .list-lower-roman, .list-lower-roman > ul, .list-lower-roman > ol {
    list-style-type: lower-roman;
  }

  /**
   * No margin o padding for last child element
   */
  .no-spacing-last-child {
    & > li:last-child {
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }

  .no-spacing-bottom-last-child:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
  }

  /**
   * padding
   */
  .pl-0 {
    padding-left: 0;
  }

  .px-300 {
    padding-left: calc-size(map-get($spacing, '300'));
    padding-bottom: calc-size(map-get($spacing, '300'));
  }

  /**
   * width
   */
  .w-fit {
    width: fit-content;
  }

  /**
   * max width
   */
  .w-max {
    width: max-content;
  }

  /**
   *
   */
  .mx-auto {
    margin-left: auto;
    margin-right: auto;
  }

  /**
   * Text-decoration
   */
  .underline {
    text-decoration: underline;
  }

  /**
   * Custom mark types
   */
  .list-mark-type-check,
  .list-mark-type-warn {
    $mark-width: 20px;
    $left-spacing: calc-size($mark-width + map-get($spacing, '150'));

    li {
      position: relative;
      padding-left: $left-spacing;
    }

    li:before {
      position: absolute;
      margin-left: -#{$left-spacing};
      top: 50%;
      transform: translate(0, -50%);
    }
  }

  .list-mark-type-check {
    li:before {
      content: url('/assets/svg/mark-type-check.svg');
    }
  }
  .list-mark-type-check-2 {
    li:before {
      margin-right: 10px;
      content: url('/assets/svg/checkmark2.svg');
    }
  }

  .list-mark-type-warn {
    li:before {
      content: url('/assets/svg/warning.svg');
    }
  }

  /**
   * Hover styles
   */
  .hover-underline:hover {
    text-decoration: underline;
  }

  .hover-underline:hover {
    text-decoration: underline;
  }

  .hover-opacity-50:hover {
    opacity: 0.5;
  }
}
